<template>
	<div id="comp-core-side-nav" class="w3-container w3-cell sidenav">
		<h1>자동자료수집관리</h1>

    <div v-if="role == 'USER' || role == 'AGENT' || role == 'CLIENT_MANAGER' || role == 'AGENT_MANAGER'">
      <hr class="sidenav-divider">
      <div class="sidenav-heading"><i class="fas fa-clipboard-list"></i>인증정보관리</div>
      <ul class="nav-ul">
        <div v-if="role == 'AGENT' || role == 'AGENT_MANAGER' || role == 'CLIENT_MANAGER'">
          <li class="nav-item">
            <router-link class="nav-link" to="/auth/hometax/agent"><i class="fas fa-chevron-right"></i>홈택스(세무대리인)</router-link>
          </li>
        </div>
        <div v-if="role == 'USER' || role == 'CLIENT_MANAGER'">
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/auth/hometax/user"><i class="fas fa-chevron-right"></i>홈택스(일반사용자)</router-link>
          </li> -->
          <li class="nav-item" v-if="client != 'yoglro'">
            <router-link class="nav-link" to="/auth/publiccorporation"><i class="fas fa-chevron-right"></i>홈택스/건강/연금/요양</router-link>
          </li>
          <li class="nav-item" v-if="client == 'yoglro'">
            <router-link class="nav-link" to="/auth/publiccorporation"><i class="fas fa-chevron-right"></i>건강보험/국민연금</router-link>
          </li>
          <li class="nav-item" v-if="client != 'yoglro'">
            <router-link class="nav-link" to="/auth/credit"><i class="fas fa-chevron-right"></i>여신금융협회</router-link>
          </li>
          <li class="nav-item" v-if="client != 'yoglro'">
            <router-link class="nav-link" to="/auth/card"><i class="fas fa-chevron-right"></i>신용카드</router-link>
          </li>
          <li class="nav-item" v-if="client != 'yoglro'">
            <router-link class="nav-link" to="/auth/bank"><i class="fas fa-chevron-right"></i>은행</router-link>
          </li>
          <li class="nav-item" v-if="client != 'yoglro'">
            <router-link class="nav-link" to="/auth/pos"><i class="fas fa-chevron-right"></i>카드단말기</router-link>
          </li>
        </div>
      </ul>
    </div>

    <div v-if="role == 'USER' || role == 'AGENT' || role == 'CLIENT_MANAGER' || role == 'AGENT_MANAGER'">
      <hr class="sidenav-divider">
      <div class="sidenav-heading"><i class="fas fa-coins"></i>스크랩핑 관리</div>
      <ul class="nav-ul">
        <li class="nav-item">
          <router-link class="nav-link" to="/scrap/useMng"><i class="fas fa-chevron-right"></i>스크랩핑 사용여부 관리</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/scrap/history"><i class="fas fa-chevron-right"></i>스크랩핑 결과조회</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/scrap/manual"><i class="fas fa-chevron-right"></i>스크랩핑 수동실행 </router-link>
        </li>
      </ul>
    </div>

    <div v-if="role == 'CLIENT_MANAGER'">
      <hr class="sidenav-divider">
      <div class="sidenav-heading"><i class="fas fa-cog"></i>연계시스템 관리</div>
      <ul class="nav-ul">
        <li class="nav-item">
          <router-link class="nav-link" to="/linkedsys/common"><i class="fas fa-chevron-right"></i>공통 설정</router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link class="nav-link" to=""><i class="fas fa-chevron-right"></i>수신내역</router-link>
        </li> -->
      </ul>
    </div>
		<hr class="sidenav-divider">
	</div>
</template>
<script>

export default {
	name: 'CompCoreSideNav',

	props: {
		role: {
			type: String,
			required: true
		},
    client: {
      type: String,
      required: true
    }
	},
	data: () => ({

	}),
}
</script>